//赛事中心的头部组件
<template>
  <div class="competition_head">
    <!--  导航条部分 -->
    <div class="content">
      <!-- logo部分 -->
      <div class="logo">
        <img height="58px" src="../assets/img/logo.png" />
      </div>

      <!-- 导航条部分 -->
      <div class="top_head">
        <div :class="['item_competion', { white_bg: index == 1 }]" @click="goCompetion" style="margin-right: 55px; cursor: pointer;">赛事中心</div>
        <div style="margin-right: 55px; cursor: pointer;" @click.stop="enterCourseCenter">课程中心</div>
        <div :class="['item_competion', { white_bg: index == 2 }]" style="margin-right: 55px; cursor: pointer;" @click.stop="goDownLoad">
          下载
        </div>
      </div>

      <!-- 登录和登录后显示的头像以及角色类型部分 -->
      <div class="user_message" @mousemove="isShowPop = true" @mouseleave="isShowPop = false">
        <!-- 未登录 -->
        <div style="font-size: 18px; font-weight: bold;" v-if="!this.$store.state.user.loginState" class="course_style" @click="doLoginClick">
          登&nbsp;&nbsp;录
        </div>

        <!-- 已经登录 -->
        <div v-if="this.$store.state.user.loginState">
          <div class="login_info">
            <img class="user_img" :src="user_img" width="48px" />
            <div class="user_type">{{ userName }}</div>
            <!-- 用户资料,我的班级,退出登陆的Pop  -->
            <div class="person_msg animated fadeIn" v-show="isShowPop">
              <div class="item_pop" v-for="(item, index) in this.$store.state.user.navArr" :key="index" @click.stop="goItemPage(index)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//引入顶部导航栏
import TopNav from "@/components/TopNav.vue";
import TopNavUnLogin from "@/components/TopNavUnLogin.vue";
export default {
  data() {
    return {
      //是否显示Pop
      isShowPop: false,
      //用户是否显示背景色 1-赛事中心 2-资源下载
      index: 1,
      user_img: "",
      userName: "",
      //点击位置的标识,是点击的登录还是课程中心的登录
      clickType: -1
    };
  },
  created() {
    //初次进入设置用户信息
    this.setUserInfo();
    //设置监听
    this.initListener();
  },

  methods: {
    //获取用户信息
    setUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.isEmpty(userInfo)) {
        this.user_img = userInfo.user_img;
        this.userName = userInfo.name;
      }
    },

    //设置监听
    initListener() {
      if (this.$store.state.user.loginState) {
        //注册VueBus的监听
        this.$bus.on("updateUserInfo", () => {
          //重新设置用户头像和姓名
          this.setUserInfo();
        });
      }
    },

    //课程中心的点击事件
    enterCourseCenter() {
      //点击课程的弹框
      this.clickType = 2;
      //判断用是否登录
      if (this.hasLogin()) {
        //已经登录,跳转到课程中心
        this.$router.push("/LoginContain/CourseCenter");
      } else {
        //弹出登录框
        this.$emit("showLoginDialog", { type: this.clickType });
      }
    },

    //赛事中心
    goCompetion() {
      if (this.index != 1) {
        this.index = 1;
        this.$router.push("/competitonCenter/LessonCenter");
      }
    },

    //资源下载
    goDownLoad() {
      if (this.index != 2) {
        this.index = 2;
        this.$router.push("/competitonCenter/DownLoad");
      }
    },

    //点击登录的操作
    doLoginClick() {
      //点击登录的弹框
      this.clickType = 1;
      //触发父组件的弹出登录对话框的方法
      this.$emit("showLoginDialog", { type: this.clickType });
    },

    //跳转不同页面
    goItemPage(index) {
      //获取当前的导航条的名称
      let navArr = this.$store.state.user.navArr;
      let navArrName = navArr[index].name;
      if (navArrName == "用户资料") {
        //用户资料
        this.$router.push({ path: "/LoginContain/stuCenter" });
      } else if (navArrName == "我的班级") {
        //学生角色
        if (this.$route.path != "/LoginContain/StudentMyClass") {
          this.$router.push({ path: "/LoginContain/StudentMyClass" });
        }
      } else if (navArrName == "班级管理") {
        //老师角色
        if (this.$route.path != "/LoginContain/TeacherManagerClass") {
          this.$router.push({
            path: "/LoginContain/TeacherManagerClass"
          });
        }
      } else if (navArrName == "退出登录") {
        //退出登录
        this.$emit("showLogout");
      }
      this.isShowPop = false;
    }
  },

  beforeDestroy() {
    //销毁时关闭事件
    this.$bus.off("updateUserInfo");
  },

  components: {
    TopNav,
    TopNavUnLogin
  }
};
</script>
<style lang="scss" scoped>
.competition_head {
  height: 87px;
  width: 100%;
  background: $common_bg;
  .content {
    height: 100%;
    width: $common_width;
    margin: 0 auto;
    display: flex;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .top_head {
      display: flex;
      color: white;
      font-weight: bold;
      font-size: 16px;
      align-items: center;
      justify-content: center;

      .item_competion {
        width: 122px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-right: 36px;
        border-radius: 18px;
        color: white;
        font-weight: bold;
      }

      .white_bg {
        color: $common_bg;
        background: white;
      }
    }

    //登录后信息
    .login_info {
      display: flex;
      cursor: pointer;
      align-items: center;
      height: 100%;
      color: white;
      font-size: 14px;
      .user_type {
        margin-left: 12px;
      }
      .user_img {
        width: 50px;
        height: 50px;
        border-radius: 40px;
        object-fit: cover;
        border: 1px solid white;
      }
      .person_msg {
        width: 110px;
        position: absolute;
        top: 87px;
        left: 0px;
        background: white;
        z-index: 999;
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        box-shadow: 0px 0px 0px 0px #d4d6d7, 0px 0px 0px 0px #d4d6d7, 2px 0px 5px 1px #d4d6d7, 0px 2px 5px 1px #d4d6d7;

        .item_pop {
          cursor: pointer;
          height: 33px;
          align-items: center;
          justify-content: center;
          display: flex;
          color: black;
          font-size: 12px;
        }

        .item_pop:hover {
          background: rgba($color: #000000, $alpha: 0.1);
          color: $common_bg;
        }
      }
    }

    // 用户登录信息以及登录后的头像信息
    .user_message {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 160px;
      color: white;
      font-size: 14px;
    }

    .course_style {
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
