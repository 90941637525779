<!-- 赛事中心-课程中心 -->
<template>
  <div class="competition_center">
    <!-- 头部 -->
    <CompetitonHead @showLogout="showLogout" ref="cpHead" @showLoginDialog="showLoginDialog"></CompetitonHead>

    <!-- 中间的切换部分 -->
    <div class="content">
      <router-view></router-view>
    </div>

    <!-- 登录页的弹框 -->
    <LoginPage @close="closeLoginDialog" :showDialog="showLoginPageDialog" @doLoginCommit="doLoginCommit" />

    <!-- 退出登录的弹框 -->
    <exit-system :showDialog="isShowLoginOut" :title="title" @doLoginOut="doLoginOut" @close="closeLoginOutDialog"></exit-system>
  </div>
</template>

<script>
//引入头部组件
import CompetitonHead from "@/components/CompetitionHead";

//登录页弹框
import LoginPage from "@/views/dialog/LoginPage";

//退出登录的弹框
import exitSystem from "@/views/dialog/ExitSystem";

export default {
  components: { CompetitonHead, LoginPage, exitSystem },
  props: {},
  data() {
    return {
      showLoginPageDialog: false,
      isShowLoginOut: false,
      //退出登录的弹框标题
      title: "退出登录",
      //记录传递地址
      clickType: -1
    };
  },
  created() {},
  mounted() {},
  methods: {
    //弹出登录的对话框
    showLoginDialog(evnent) {
      this.clickType = evnent.type;
      this.showLoginPageDialog = true;
    },
    //关闭登录按钮
    closeLoginDialog() {
      this.showLoginPageDialog = false;
    },

    //显示退出登录的弹框
    showLogout() {
      this.isShowLoginOut = true;
    },

    //登录提交
    doLoginCommit() {
      this.showSuccessMsg("登录成功");
      this.showLoginPageDialog = false;
      if (this.clickType == 1) {
        //只登录,并且更新用户名称和头像信息
        //更新用户名称和头像信息
        this.$refs.cpHead.setUserInfo();
        this.$refs.cpHead.initListener();
      } else if (this.clickType == 2) {
        //课程中心的点击事件
        this.$router.push("/LoginContain/CourseCenter");
      }
    },

    //关闭退出登录的弹框
    closeLoginOutDialog() {
      this.isShowLoginOut = false;
    },

    //退出登录的操作
    doLoginOut() {
      //退出登录的操作
      localStorage.removeItem("userInfo");
      localStorage.removeItem("autoLogin");
      //还原vueX的数据状态
      this.$store.commit("user/setLoginState", false);
      this.$store.commit("user/setUserInfo", {});
      this.$store.commit("user/setNavArr", []);
      this.$router.replace("/");
    }
  }
};
</script>

<style lang="scss" scoped>
//赛事中心
.competition_center {
  height: 100vh;
  .content {
    overflow-y: auto;
    height: calc(100vh - 87px);
    background: url("../../assets/img/competitioncenter_bg.png") no-repeat;
  }
}
</style>
